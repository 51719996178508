import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Joi from '../../utils/validator';
import { Form } from 'antd';
import notifier from '../../utils/notifier';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const CREATE_COMPENSATION_SCHEMA = {
    // wage_rate:Joi.string().valid('Ten', 'Twenty','Thirty').required(),
    // wage_rate: Joi.string().label('wage rate').required(),
    overtime: Joi.string().label('overtime').required(),
    lenght_of_employement: Joi.string().label('lenght employement').required(),
    time_away_accident: Joi.string().label('accident due').required(),
    injuries_sustained: Joi.string().label(' Injuries Sustained').required(),
}

const initialState = {
    wage_rate: null,
    overtime: null,
    lenght_of_employement: null,
    time_away_accident: null,
    injuries_sustained: null,
};

const Slide3 = props => {
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({ ...initialState });


    const handleChange = useCallback((key, val) => {
        // Validate individual
        setErrors(errors => ({
            ...errors,
            [key]: Joi.validateToPlainErrors(val, CREATE_COMPENSATION_SCHEMA[key]),
        }));

        setData({
            ...data,
            [key]: val,
        });
    })

    const handleNext = async (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        // Validate all fields
        const errors = Joi.validateToPlainErrors(data, CREATE_COMPENSATION_SCHEMA);
        setErrors(errors);

        if (Joi.hasPlainError(errors)) {
            // notifier.error('Please fix errors');
            return;
        }
        props.setSlideData({ ...data, ...props.slideData });
        props.setStep(4);
    }
    return (
        <div className={(props.button == "back" ? "w3-animate-left" : "w3-animate-right") + " step_slider_box"} >
            <div className="step_group">
                <div className="row">

                    <div className="col-md-6 mob-steps">
                        <div className="step_count">
                            <img src="./images/mob-step_3.png" alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="step_content_card">
                            <div className>
                                <h3>Worker's Compensation Survey</h3>
                                <div className="progress step-progress">
                                    <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>50%</div>
                                </div>
                            </div>
                            <div className="step_content_form">
                                <h4>Compensation</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input mui-input-active">
                                            <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'wage_rate') ? 'error' : ''}
                                            >
                                                <InputLabel id="select-age-label">Wage Rate (Pay Before deductions)</InputLabel>
                                                <Select
                                                    labelId="select-age-label"
                                                    id="select-age"
                                                    label="Wage Rate (Pay Before deductions)"
                                                    onChange={v => handleChange('wage_rate', v.target.value)}
                                                    value={data.wage_rate}
                                                >
                                                    <MenuItem value={10}>Ten</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'wage_rate')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input mui-input-active">

                                            <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'overtime') ? 'error' : ''}
                                            >
                                                <TextField
                                                    error={Joi.getFirstPlainError(errors, 'overtime') ? 'error' : ''}
                                                    id="outlined-basic" label="Overtime (Hours Worked Weekly)" variant="outlined"
                                                    value={data.overtime}
                                                    placeholder="Overtime (Average Number of Hours Worked Weekly)"
                                                    onChange={v => handleChange('overtime', v.target.value)}
                                                    type="text" />
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'overtime')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input mui-input-active">
                                            <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'lenght_of_employement') ? 'error' : ''}
                                            >
                                                <TextField
                                                    error={Joi.getFirstPlainError(errors, 'lenght_of_employement') ? 'error' : ''}
                                                    id="outlined-basic" label="Length of Employment with Employer" variant="outlined"
                                                    value={data.lenght_of_employement}
                                                    placeholder="OLength of Employment with Employer"
                                                    onChange={v => handleChange('lenght_of_employement', v.target.value)}
                                                    type="text" />
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'lenght_of_employement')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input mui-input-active">
                                            <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'time_away_accident') ? 'error' : ''}
                                            >
                                                <TextField
                                                    error={Joi.getFirstPlainError(errors, 'time_away_accident') ? 'error' : ''}
                                                    id="outlined-basic" label="Time Away from Work due to Accident" variant="outlined"
                                                    value={data.time_away_accident}
                                                    placeholder="Time Away from Work due to Accident"
                                                    onChange={v => handleChange('time_away_accident', v.target.value)}
                                                    type="text" />
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'time_away_accident')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input m-0 mui-input-active">
                                        <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'injuries_sustained') ? 'error' : ''}
                                            >
                                                <TextField
                                                    error={Joi.getFirstPlainError(errors, 'injuries_sustained') ? 'error' : ''}
                                                    id="outlined-basic" label="Injuries Sustained" variant="outlined"
                                                    value={data.injuries_sustained}
                                                    placeholder="Injuries Sustained"
                                                    onChange={v => handleChange('injuries_sustained', v.target.value)}
                                                    type="text" />
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'injuries_sustained')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center step-btn-group">
                                <a className="step-btn mr-2"  role="button" data-slide="prev">
                                    <div className="btn sec-btn" data-dismiss="modal" onClick={() => { props.setStep(2); }}>Back</div>
                                </a>
                                <a className="step-btn"  role="button" data-slide="next">
                                    <div className="btn main-btn" onClick={() => { handleNext() }}>Next</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 desktop-steps">
                        <div className="step_count">
                            <img src="./images/step_3.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Slide3.propTypes = {

};

export default Slide3;