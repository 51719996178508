import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Joi from '../../utils/validator';
import { Form } from 'antd';
import notifier from '../../utils/notifier';
import axios from 'axios';
import endpoint_url from '../Calendar/utils/conf';
import { TextField, FormControl, FormHelperText } from '@mui/material';


const CREATE_COMPENSATION_SCHEMA = {
    job_title: Joi.string().label('Job Title').required(),
    job_duties: Joi.string().label('duties time').required(),
    // is_reported: Joi.string().label('Reported ').required(),
    // whom_accident_reported: Joi.string().label(' whom ccident reported').required(),
    // is_witness: Joi.string().label(' witness').required(),
    // witness_name: Joi.string().label('Witness Name').required(),
}

const initialState = {
    job_title: null,
    job_duties: null,
    is_reported: "yes",
    whom_accident_reported: null,
    is_witness: "yes",
    witness_name: null,
};
const Slide4 = props => {
    const [errors, setErrors] = useState({});
    let [slideData, setSlideData] = useState({});
    const [data, setData] = useState({ ...initialState });


    const handleChange = useCallback((key, val) => {
        // Validate individual
        setErrors(errors => ({
            ...errors,
            [key]: Joi.validateToPlainErrors(val, CREATE_COMPENSATION_SCHEMA[key]),
        }));

        setData({
            ...data,
            [key]: val,
        });
    })

    const handleNext = async (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        // Validate all fields
        const errors = Joi.validateToPlainErrors(data, CREATE_COMPENSATION_SCHEMA);
        setErrors(errors);

        if (Joi.hasPlainError(errors)) {
            // notifier.error('Please fix errors');
            return;
        }
        props.setSlideData({ ...data, ...props.slideData });
        handleSubmit();
    }

    const handleSubmit = async () => {
        let finalData = { ...data, ...props.slideData };
        await axios({
            // url: endpoint_url + "/survey.php",
            url: "http://aap.local/survey.php",
            
            method: "POST",
            data: finalData,

        }).then((response) => {
            console.log("hiiiiiii", finalData)
            props.setStep(5);
            props.setButton("next");
        }).catch((error) => {
        });
    }

    return (
        <>
            <div className={(props.button == "back" ? "w3-animate-left" : "w3-animate-right") + " step_slider_box"} >
                <div className="step_group">
                    <div className="row">
                        <div className="col-md-6 mob-steps">
                            <div className="step_count">
                                <img src="./images/mob-step_3.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="step_content_card">
                                <div className>
                                    <h3>Worker's Compensation Survey</h3>
                                    <div className="progress step-progress">
                                        <div className="progress-bar" role="progressbar" style={{ width: '75%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>75%</div>
                                    </div>
                                </div>
                                <div className="step_content_form">
                                    <h4>Compensation</h4>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="contact_f_box mui-form-input mui-input-active">
                                                <FormControl
                                                    fullWidth
                                                    error={Joi.getFirstPlainError(errors, 'job_title') ? 'error' : ''}
                                                >
                                                    <TextField
                                                        error={Joi.getFirstPlainError(errors, 'job_title') ? 'error' : ''}
                                                        id="outlined-basic" label="Job Title at Time of Accident"
                                                        variant="outlined"
                                                        value={data.job_title}
                                                        placeholder="Job Title at Time of Accident"
                                                        onChange={v => handleChange('job_title', v.target.value)}
                                                        type="text" />
                                                    <FormHelperText>{Joi.getFirstPlainError(errors, 'job_title')}</FormHelperText>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="contact_f_box mui-form-input mui-input-active">
                                                <FormControl
                                                    fullWidth
                                                    error={Joi.getFirstPlainError(errors, 'job_duties') ? 'error' : ''}
                                                >
                                                    <TextField
                                                        error={Joi.getFirstPlainError(errors, 'job_duties') ? 'error' : ''}
                                                        id="outlined-basic" label="Regular Job Duties at Time of Accident"
                                                        variant="outlined"
                                                        value={data.job_duties}
                                                        placeholder="Regular Job Duties at Time of Accident"
                                                        onChange={v => handleChange('job_duties', v.target.value)}
                                                        type="text" />
                                                    <FormHelperText>{Joi.getFirstPlainError(errors, 'job_duties')}</FormHelperText>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="contact_f_box mb-3">
                                                <label>Was the Accident Reported?</label>
                                                <div className="d-flex">
                                                    <div className="d-flex align-items-center mr-4">
                                                        <Form.Item
                                                            validateStatus={Joi.getFirstPlainError(errors, 'is_reported') ? 'error' : ''}
                                                            help={Joi.getFirstPlainError(errors, 'is_reported')}
                                                        >
                                                            <input
                                                                className="w-auto mr-2"
                                                                // defaultChecked
                                                                value="yes"
                                                                name="is_reported"
                                                                id="is_reported_yes"
                                                                onChange={v => v.target.checked && handleChange('is_reported', v.target.value)}
                                                                type="radio"
                                                                checked={data.is_reported === 'yes'}
                                                            />
                                                        </Form.Item>
                                                        <label className="form-check-label m-0" htmlFor="is_reported">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            value="No"
                                                            id="is_reported_no"
                                                            name="is_reported"
                                                            onChange={v => v.target.checked && handleChange('is_reported', v.target.value)}
                                                            checked={data.is_reported === 'No'}
                                                            className="w-auto mr-2" type="radio"
                                                        />
                                                        <label className="form-check-label m-0" htmlFor="is_reported">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            data.is_reported === 'yes' &&
                                            <div className="col-md-12">
                                                <div className="contact_f_box mui-form-input mui-input-active">
                                                    <FormControl
                                                        fullWidth
                                                        error={Joi.getFirstPlainError(errors, 'whom_accident_reported') ? 'error' : ''}
                                                    >
                                                        <TextField
                                                            error={Joi.getFirstPlainError(errors, 'whom_accident_reported') ? 'error' : ''}
                                                            id="outlined-basic" label="To whom was the accident reported?"
                                                            variant="outlined"
                                                            value={data.whom_accident_reported}
                                                            placeholder="To whom was the accident reported?"
                                                            onChange={v => handleChange('whom_accident_reported', v.target.value)}
                                                            type="text" />
                                                        <FormHelperText>{Joi.getFirstPlainError(errors, 'whom_accident_reported')}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        }


                                        <div className="col-md-12">
                                            <div className="contact_f_box mb-4">
                                                <label>Was there a witness to the accident?</label>
                                                <div className="d-flex">
                                                    <div className="d-flex align-items-center mr-4">
                                                        <Form.Item
                                                            validateStatus={Joi.getFirstPlainError(errors, 'is_witness') ? 'error' : ''}
                                                            help={Joi.getFirstPlainError(errors, 'is_witness')}
                                                        >
                                                            <input
                                                                className="w-auto mr-2"
                                                                // defaultChecked
                                                                id="is_witness_yes"
                                                                value="yes"
                                                                name="is_witness"
                                                                onChange={e => e.target.checked && handleChange('is_witness', e.target.value)}
                                                                checked={data.is_witness === 'yes'}
                                                                type="radio" />
                                                        </Form.Item>
                                                        <label className="form-check-label m-0" htmlFor="is_witness">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            value="No"
                                                            name="is_witness"
                                                            onChange={e => e.target.checked && handleChange('is_witness', e.target.value)}
                                                            className="w-auto mr-2" type="radio" id="is_witness_no"
                                                            checked={data.is_witness === 'No'}
                                                        />
                                                        <label className="form-check-label m-0" htmlFor="is_witness">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            data.is_witness === 'yes' &&
                                            <div className="col-md-12">
                                                <div className="contact_f_box mui-form-input mui-input-active">
                                                    <FormControl
                                                        fullWidth
                                                        error={Joi.getFirstPlainError(errors, 'witness_name') ? 'error' : ''}
                                                    >
                                                        <TextField
                                                            error={Joi.getFirstPlainError(errors, 'witness_name') ? 'error' : ''}
                                                            id="outlined-basic" label="Mention Witness Name"
                                                            variant="outlined"
                                                            value={data.witness_name}
                                                            placeholder="Mention Witness Name"
                                                            onChange={v => handleChange('witness_name', v.target.value)}
                                                            type="text" />
                                                        <FormHelperText>{Joi.getFirstPlainError(errors, 'witness_name')}</FormHelperText>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        }

                                        <div className="col-md-12">
                                            <div className="d-flex align-items-start">
                                                <input className="w-auto mr-2 mt-1" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                                <label className="form-check-label check-box-text m-0" htmlFor="flexRadioDefault2">
                                                    I agree to my information being shared and gathered between services.<br />
                                                    Your consent to share personal information is entirely voluntary and you may withdraw your consent at any time. Should you have any questions
                                                    about this process,  or wish to withdraw your consent please contact.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center step-btn-group">
                                    <a className="step-btn mr-2" role="button" data-slide="prev">
                                        <div className="btn sec-btn" data-dismiss="modal" onClick={() => { props.setStep(3); }} >Back</div>
                                    </a>
                                    <a className="step-btn" role="button" data-slide="next">
                                        <div className="btn main-btn" onClick={() => {handleNext() }}>Next</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 desktop-steps">
                            <div className="step_count">
                                <img src="./images/step_3.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Slide4.propTypes = {

};

export default Slide4;