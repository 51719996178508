import React, { Fragment, useState } from 'react'
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import Slide5 from './Slide5';

function Survey(props) {

    let [step, setStep] = useState(1);
    let [slideData, setSlideData] = useState({});
    let [button, setButton] = useState("next");
    console.log("data::",slideData)
    return (
        <Fragment>
            <NavBar />
            <section className="content-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {
                                step == 1 
                                ? <Slide1 setStep={setStep} step={step} setSlideData={setSlideData} slideData={slideData} setButton={setButton} button={button}/>
                                : step == 2 
                                ? <Slide2 setStep={setStep} step={step} setSlideData={setSlideData} slideData={slideData}  setButton={setButton} button={button}/>  
                                : step == 3 
                                ? <Slide3 setStep={setStep} step={step} setSlideData={setSlideData} slideData={slideData}  setButton={setButton} button={button}/>
                                : step == 4 
                                ? <Slide4 setStep={setStep} step={step} setSlideData={setSlideData} slideData={slideData}  setButton={setButton} button={button}/>
                                : <Slide5 setStep={setStep} step={step} setSlideData={setSlideData} slideData={slideData} />
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    );
}

export default Survey;