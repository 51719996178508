import React, { useState, useCallback } from 'react';
import Joi from '../../utils/validator';
import moment from 'moment';
import { TextField, FormControl, FormHelperText } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CREATE_ACCIDENT_DETAILS_SCHEMA = {
    on_clock_time: Joi.string().label('clock at the time').required(),
    accident_location: Joi.string().label('accident location').required(),
    accident_date: Joi.string().label('accident happend').required(),
    // is_involved: Joi.string().label(' involved in an accident').required(),
    accident_description: Joi.string().label(' accident description').required(),
}

const initialState = {
    is_involved: 'Yes',
    on_clock_time: null,
    accident_location: null,
    accident_date: "",
    accident_description: null,
};

const Slide2 = props => {
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({ ...initialState });

    const handleChange = useCallback((key, val) => {
        // Validate individual
        setErrors(errors => ({
            ...errors,
            [key]: Joi.validateToPlainErrors(val, CREATE_ACCIDENT_DETAILS_SCHEMA[key]),
        }));

        setData({
            ...data,
            [key]: val,
        });
    })

    const handleNext = async (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        // Validate all fields
        const errors = Joi.validateToPlainErrors(data, CREATE_ACCIDENT_DETAILS_SCHEMA);
        setErrors(errors);

        if (Joi.hasPlainError(errors)) {
            // notifier.error('Please fix errors');
            return;
        }
        props.setSlideData({ ...data, ...(props.slideData) });
        props.setStep(3);
        props.setButton("next");
    }

    const handleDatePicker = (date, dateString, key) => {
        console.log(date, dateString, key)
        handleChange(key, date ? date.format("YYYY/MM/DD") :'');
    };

 
    return (
        <div className={(props.button == "back" ? "w3-animate-left" : "w3-animate-right") + " step_slider_box"} >
            <div className="step_group">
                <div className="row">
                    <div className="col-md-6 mob-steps">
                        <div className="step_count">
                            <img src="./images/mob-step_2.png" alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="step_content_card">
                            <div className>
                                <h3>Worker's Compensation Survey</h3>
                                <div className="progress step-progress">
                                    <div className="progress-bar" role="progressbar" style={{ width: '25%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>25%</div>
                                </div>
                            </div>
                            <div className="step_content_form">
                                <h4>Accident Details</h4>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="contact_f_box mui-radio-btn">
                                            <label>Were you involved in an accident?</label>
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center mr-4 form-item-holder">
                                                    <input
                                                        value={data.is_involved}
                                                        defaultChecked
                                                        onChange={v => handleChange('is_involved', v.target.value)}
                                                        className="w-auto mr-2" type="radio" name="is_involved" id="is_involved" />
                                                    <label className="form-check-label m-0" htmlFor="is_involved">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        value="No"
                                                        onChange={v => handleChange('is_involved', v.target.value)}
                                                        className="w-auto mr-2" type="radio" name="is_involved" id="is_involved"
                                                    />
                                                    <label className="form-check-label m-0" htmlFor="is_involved">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input mui-input-active">
                                            <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'on_clock_time') ? 'error' : ''}
                                            >
                                                <TextField
                                                    error={Joi.getFirstPlainError(errors, 'on_clock_time') ? 'error' : ''}
                                                    id="outlined-basic" label="Were you on the clock at the time?"
                                                    variant="outlined"
                                                    value={data.on_clock_time}
                                                    placeholder="Were you on the clock at the time?"
                                                    onChange={v => handleChange('on_clock_time', v.target.value)}
                                                    type="text" />
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'on_clock_time')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input mui-input-active">
                                            <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'accident_location') ? 'error' : ''}
                                            >
                                                <TextField
                                                    error={Joi.getFirstPlainError(errors, 'accident_location') ? 'error' : ''}
                                                    id="outlined-basic" label="Where did the accident happen (location)?"
                                                    variant="outlined"
                                                    value={data.accident_location}
                                                    onChange={v => handleChange('accident_location', v.target.value)}
                                                    type="text" />
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'accident_location')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input mui-input-active">
                                            <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'accident_date') ? 'error' : ''}
                                            >

                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        format="YYYY/MM/DD"
                                                        value={data.accident_date}
                                                        label="When did the accident happen?"
                                                        onChange={(date, dateString) => handleDatePicker(date, dateString, 'accident_date')}

                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'accident_date')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="contact_f_box mui-form-input mui-input-active">
                                            <FormControl
                                                fullWidth
                                                error={Joi.getFirstPlainError(errors, 'accident_description') ? 'error' : ''}
                                            >
                                                <TextField
                                                    error={Joi.getFirstPlainError(errors, 'accident_description') ? 'error' : ''}
                                                    id="outlined-multiline-static"
                                                    label="Describe how the accident happened"
                                                    multiline
                                                    fullWidth
                                                    rows={4}
                                                    onChange={v => handleChange('accident_description', v.target.value)}
                                                    defaultValue={data.accident_description}
                                                />
                                                <FormHelperText>{Joi.getFirstPlainError(errors, 'accident_description')}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center step-btn-group">
                                    <a className="step-btn mr-2" role="button" data-slide="prev">
                                        <div className="btn sec-btn" data-dismiss="modal" onClick={() => { props.setStep(1); props.setButton("back"); }}>Back</div>
                                    </a>
                                    <a className="step-btn" role="button" data-slide="next">
                                        <div className="btn main-btn" onClick={() => { handleNext() }}>Next</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 desktop-steps">
                        <div className="step_count">
                            <img src="./images/step_2.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slide2;