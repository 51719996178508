import React, { Fragment } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

function Error404(props) {
    return (
        <Fragment>
            <NavBar/>
            <div className="policy-term">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="mb-0 text-primar">404</h2>
                            <h2 className="mt-0">Page not found</h2>
                            <br/>
                            <hr className="w-25 m-auto" />
                            <br/>
                            <p className="text-secondary">The link is broken or the page has been moved.</p>
                            <a className="btn btn-outline-primary" href="/">Back to Home</a>
                        </div>
                    </div>
                    <div className="row"></div>
                </div>
            </div>
            {/* <!-- Policy end--> */}
            <section className="footer-patten">
                <img className="w-100" src="./images/get-started-patten.png" alt="" />
            </section>
            {/* <!-- Policy end--> */}
            <Footer/>
        </Fragment>
    );
}

export default Error404;