import React, { Fragment } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

function Home(props) {
    return (
        <Fragment>
            <NavBar/>

            {/* <!-- Hero banner Start--> */}
            <section className="hero-baner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center">
                            <div className="hero-content">
                                <h1 className="hero-title">Ask Questions with a<br/><span>Licensed Professional</span></h1>
                                <p className="hero-text">With a Best Interest or<br/>Fiduciary Responsibility</p>
                                <a href="/setup-an-appointment" className="btn main-btn">Schedule a Free Consultation</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img className="hero-img" src="./images/hero-img.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Hero banner end--> */}

            {/* <!-- Ask Questions Start--> */}
            <section className="ask-questions">
                <div className="container">
                    <div className="row">
                        <div className="ask-title-box text-center">
                            <h1 className="ask-title">Ask Questions About the Following Topics</h1>
                        </div>
                        <div className="ask-icon-wrapper">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-1.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Tax Reduction<br/>Strategies</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-2.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Tax-free<br/>income</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-3.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Business<br/>owners </h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-4.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Asset<br/>protection</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-5.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Trust Setup</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-6.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Estate<br/>planning</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-7.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Retirement<br/>Planning</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-8.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Brokerage<br/>Second Opinion</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-9.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Portfolio<br/>diversification</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-10.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Pension<br/>protection</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-11.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Mortgage<br/>protection</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-12.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Debt<br/>reduction</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-13.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Beneficiary<br/>Review</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-14.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Life Insurance<br/>Review</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-18.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Annuity<br/>Review</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-15.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">College<br/>planning</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-16.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Premium<br/>Financing</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-17.png" alt="" />
                                            </div>
                                            <h3 className="icon-title">Crypto</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-19.png" alt="" />
                                            </div>
                                            <h3 className="icon-title mb-0">Long Term<br/>Care</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="ask-icon">
                                            <div className="icon-wrapper">
                                                <img src="./images/ask-icon-20.png" alt="" />
                                            </div>
                                            <h3 className="icon-title mb-0">Senior Life<br/>Settlements</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Ask Questions end--> */}

            {/* <!-- Get Started Start--> */}
            <section className="get-started  position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center ccd">
                            <div className="get-started-content">
                                <h1>Ready to Get Started?</h1>
                                <a href="/setup-an-appointment" className="btn main-btn">Schedule a Free Consultation</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img className="get-started-img" src="./images/get-started-img.png" alt="" />
                        </div>
                    </div>
                </div>
                <img className="get-started-patten" src="./images/get-started-patten.png" alt="" />
            </section>
            {/* <!-- Get Started end--> */}


            {/* <!-- Footer start--> */}
            <Footer/>
        </Fragment>
    );
}

export default Home;