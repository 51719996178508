import { useState, useEffect } from 'react';
import moment from 'moment';
import { Paper, Box, Button, Typography} from '@mui/material';
import AppointmentDetail from './Calendar/Components/AppointmentDetail';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//components
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Calender  from './Calendar/Components/Calender'
import TimerView from './Calendar/Components/TimerView';
import Confirmed from './Calendar/Components/Confirmed';
import Loading from '../components/Loading';
import endpoint_url from './Calendar/utils/conf';
import Footer from '../components/Footer';

const style = {
  rightPanel: {display:"flex", justifyContent:"center", alignItem:"center", p:5, width:'100%'}
}

function ReSchedule() {
  const hasWindow = typeof window !== 'undefined';
  const [appointmentCount, setAppointmentCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [exists, setExists] = useState(false);
  const [blockTillDate, setBlockTillDate] = useState("2022-01-01"); 

  const [step, setStep] = useState(1);
  const [timezoneSelected,setTimezoneSelected] = useState('Pacific Time (PT)');
  const [timeSelected, setTimeSelect] = useState(null);
  const [dateSelected,setDateSelect] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [isDesktop, setIsDesktop] = useState(getWindowDimensions().width > 1100);
  const [isTablet,setIsTablet] = useState(getWindowDimensions().width >= 600 && getWindowDimensions().width <= 1100);
  const [isMobile,setIsMobile] = useState(getWindowDimensions().width < 600);
  const [holidays, setHolidays] = useState([])

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
        width,
        height,
    };
  }

  // create constructor.
  const loadInit = () => {
    // set loading to true
    setLoading(true);
    const id = getQueryString("id");
    const email = getQueryString("email");
    var formData = new FormData();
    formData.append('id', id);
    formData.append("email", email);

    axios({
      url:endpoint_url+"/check-exist.php",
      method:"POST",
      data: formData,
    }).then((response)=>{
      if(response.data === true) {
        setExists(true);
      }
      setLoading(false)
    }).catch((error)=>{
      // nothing...
      setLoading(false)
    });
  }

  // function to get query string from url.
  function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }

  const getDates = async () => {
    setLoadingCalendar(true)
    await axios({
      url:endpoint_url+"/get-date.php?leadid="+getQueryString("id"),
      method:"GET",
      headers:{
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    }).then((response)=>{
      setAvailableDates(response.data.data)
      setAppointmentCount(response.data.appointment_count)
      setBlockTillDate(response.data.block_till_date)
      setHolidays(response.data.holidays)
    }).catch((error)=>{
    });
    setLoadingCalendar(false)
  }

  useEffect(() => {
    if (hasWindow) {
        function handleResize() {
            setIsDesktop(getWindowDimensions().width > 1100);
            setIsTablet(getWindowDimensions().width >= 600 && getWindowDimensions().width <= 1100);
            setIsMobile(getWindowDimensions().width < 600);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  useEffect(() => {
    getDates();
    loadInit();
  }, []);

  const _handleConfirm = async (flag) => {
    if(dateSelected === null){
      NotificationManager.warning('Warning', 'Please select date first!', 3000);
      return;
    }
    if(timeSelected === null){
      NotificationManager.warning('Warning', 'Please select time first!', 3000);
      return;
    }
    if(timezoneSelected === null){
      NotificationManager.warning('Warning', 'Please select timezone first!', 3000);
      return;
    }
    setLoading(true)
    const id = getQueryString("id");
    const email = getQueryString("email");
    var formData = new FormData();
    formData.append('id', id);
    formData.append("date", moment(dateSelected).format("YYYY-MM-DD") + " " + timeSelected);
    formData.append("timezone", timezoneSelected);
    formData.append("email", email);
    // submit
    await axios({
      url: endpoint_url+"/reschedule.php",
      method:"POST",
      data:formData
    }).then((response)=>{
      setLoading(false);
        if(response.data.success){
          NotificationManager.success('Success', 'Updated Success!', 3000);
          setStep(2)
        } else {
          NotificationManager.error('Unable to Submit', 'Please refresh and try again!', 3000);
        }
    }).catch((error)=>{
        console.log(error);
    });

    setLoading(false)
  }

  return (
    <>
     <Paper sx={
                isDesktop ? 
                { 
                    width: dateSelected === null ? "60%" : "65%", 
                    display:"flex", justifyContent:"start", alignItem:"center", 
                    my:5, mx:'auto',
                    border: '1px solid #d3d3d3',
                    backgroundColor:"#f0f0f0",
                    position:"relative"
                }
                : { width:"90%", alignItem:"center", mt:5, mx:'auto', backgroundColor:"#f0f0f0", position:"relative"}
            }>
               
                <Loading isLoading={loading || loadingCalendar}/> 
                {step === 2 ?
                 <Confirmed time={timeSelected} date={dateSelected} timezone={timezoneSelected} isMobile={isMobile}/>
                 :
                 <>
                  <Box sx={{px:isMobile ? 0 : 5, width: isMobile || isTablet ? '100%' : '60%'}}>
                    <AppointmentDetail 
                        step={step} 
                        isDesktop={isDesktop}
                        isTablet={isTablet}
                        isMobile={isMobile}
                        setStep={setStep}
                        dateSelected={dateSelected}
                        timezoneSelected={timezoneSelected}
                        timeSelected={timeSelected}
                    />
                </Box>
                
                {/* Mobile View */}
                <Box 
                    sx={ 
                        isDesktop 
                        ? { ...style.rightPanel, borderLeft: '0.1px solid #cdcdcd' } 
                        : { ...style.rightPanel, borderTop: '0.1px solid #cdcdcd' } 
                    }>
                    {
                        <>
                            {
                                (isDesktop || isTablet || (isMobile && dateSelected === null)) && <Box>
                                  {
                                      !loadingCalendar && <Calender 
                                      dateSelected={dateSelected}
                                      setDateSelect={setDateSelect}
                                      isMobile ={isMobile}
                                      dates={availableDates} 
                                      appointmentCount={appointmentCount}
                                      blockTillDate={blockTillDate}
                                      holidays={holidays}

                                      />
                                  }
                                </Box>
                            }
                            {/* Todo: If date selected then show the timer */}
                            {
                                dateSelected && 
                                <Box width={'100%'}>
                                    { isMobile &&
                                        <Button fullWidth variant="contained" color={"success"} sx={{mb:1}} onClick={()=>{setDateSelect(null)}}>
                                            <ArrowBackIcon /> &nbsp; Back
                                        </Button>
                                    }
                                    { !loadingCalendar && <TimerView 
                                        timezoneSelected={timezoneSelected} 
                                        setTimezoneSelected={setTimezoneSelected}
                                        timeSelected={timeSelected}
                                        setTimeSelect={setTimeSelect}
                                        setStep={_handleConfirm}
                                        dateSelected={dateSelected}
                                        setDateSelect={setDateSelect}
                                        blocked={availableDates[moment(dateSelected.toString()).format('YYYY-MM-DD')]}
                                        isMobile={isMobile} /> }
                                </Box>
                            }
                        </> 
                       
                      
                    }
                </Box>
                 </>
                  
              }
               
            </Paper>
            {/* <!-- Policy end--> */}
            <section className="footer-patten">
                <img className="w-100" src="./images/get-started-patten.png" alt="" />
            </section>
            {/* <!-- Policy end--> */}
            <Footer/>
    </>
  );
}

export default ReSchedule;