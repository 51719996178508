import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
function NavBar() {
    return (
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="w-100 d-flex align-items-center justify-content-between mob-header">
                            <a href="/" className="logo">
                                <img src="./images/main-logo.png" alt="" />
                            </a>
                            <a href="mailto:support@askadvisorpro.com" className="header-email d-flex align-items-center">
                                <EmailIcon/>
                                <p>support@askadvisorpro.com</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default NavBar