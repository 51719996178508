import React from 'react';
import PropTypes from 'prop-types';


const Slide5 = props => {
    const handleSubmit = async (e) => {
        if (e && e.preventDefault) {
          e.preventDefault();
        }
        props.setStep(1);
        props.setButton("next");
    }
    return (
        <>
        
        <div className={(props.button == "back" ? "w3-animate-left" : "w3-animate-right") + " step_slider_box"} >
                <div className="step_group">
                    <div className="row">
                        <div className="col-md-6 mob-steps">
                            <div className="step_count">
                                <img src="./images/mob-step_4.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="step_content_card">
                                <div className>
                                    <h3>Worker's Compensation Survey</h3>
                                    <div className="progress step-progress">
                                        <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>100%</div>
                                    </div>
                                </div>
                                <div className="step_content_form thanks-text-center">
                                    <h4>Thank you for completing <br />this survey!</h4>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="contact_f_box">
                                                <p className="text-center m-0">We are extremely grateful for contributing your valuable time and your honest information.
                                                    This will assist in our analysis and help us implement worthwhile improvements.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center step-btn-group">
                                    <a className="step-btn"  role="button" data-slide="next">
                                        <div className="btn main-btn" onClick={() => { handleSubmit() }}>Close</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 desktop-steps">
                            <div className="step_count">
                                <img src="./images/step_4.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

Slide5.propTypes = {

};

export default Slide5;