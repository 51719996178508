import axios from 'axios'
import React, { Fragment } from 'react'
import { useState } from 'react'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import validation from '../utils/validation'
import endpoint_url from './Calendar/utils/conf'
import Loading from './Calendar/Loading';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const FORM = {
    values:{
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    },
    errors: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        subject: null,
        message: null,
    },
    validation:false
}

function Contact() {
    const [form, setForm] = useState(validation.contactForm(JSON.parse(JSON.stringify(FORM))))
    const [loading, setLoading] = useState(false)
    const [confirmation, setConfirmation] = useState(false)

    const handleChange = (key,value) => {
        let formCopy = JSON.parse(JSON.stringify(form))
        formCopy.values[key] = (key === "phone" ? formatPhone(value) : value)
        setForm(validation.contactForm(JSON.parse(JSON.stringify(formCopy))))
    }

    const formatPhone = (input) => {
        input = input.replace(/\D/g, '');
        input = input.substring(0, 10);

        var size = input.length;

        if (size < 4) { input = '(' + input; } 
        else if (size < 7) { input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6); } 
        else { input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10); }

        return input;
    }

    const handleSubmit = async () => {
        setForm(validation.contactForm(JSON.parse(JSON.stringify(form))))
        if(form.validation){
            var formData = new FormData();
            formData.append('first_name', form.values.first_name);
            formData.append('last_name', form.values.last_name);
            formData.append('email', form.values.email);
            formData.append('phone', form.values.phone);
            formData.append('subject', form.values.subject);
            formData.append('message', form.values.message);

            setLoading(true)
            await axios({
                url:endpoint_url+"/contact-us.php",
                method:"POST",
                data:formData
            }).then((response)=>{
                if(response.data.success){
                    setConfirmation(true)
                }
                setForm(FORM)
            }).catch((error)=>{
            });
            setLoading(false)
        }
    }

    return (
        <Fragment>
            <NavBar/>
            <Loading isLoading={loading} fullScreen={false}/>
            <section className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1><span>Questions?</span><br/> We're here to help.</h1>
                            {/* <p>Professional are here to help you chart a course to success. They can answer all your related questions.</p> */}
                            <div className="contact-icon-wrapper">
                                <div className="icon-box d-flex align-items-center">
                                    <div className="icon">
                                        <img src="./images/contact-icon-1.png" alt="" />
                                    </div>
                                    <div className="icon-content">
                                        <b>Address</b>
                                        <p>Benefit Specialists, LLC<br/>3911 Concord Pike Unit 8030 Wilmington, DE 19803</p>
                                    </div>
                                </div>
                                {/* <div className="icon-box d-flex align-items-center">
                                    <div className="icon">
                                        <img src="./images/contact-icon-2.png" alt="" />
                                    </div>
                                    <div className="icon-content">
                                        <b>Phone</b>
                                        <p>012-345-6789</p>
                                    </div>
                                </div> */}
                                <div className="icon-box d-flex align-items-center">
                                    <div className="icon">
                                        <img src="./images/contact-icon-3.png" alt="" />
                                    </div>
                                    <div className="icon-content">
                                        <b>Email</b>
                                        <p>support@askadvisorpro.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="w-100 contact-form-wrapper">
                                <h5>Just fill out the form, and we'll be in touch ASAP.</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>First Name <small className='text-danger'>* {form.errors.first_name}</small></label>
                                        <input type="text" placeholder="First Name" onChange={(e)=>{handleChange("first_name", e.target.value)}}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Last Name <small className='text-danger'>* {form.errors.last_name}</small></label>
                                        <input type="text" placeholder="Last Name" onChange={(e)=>{handleChange("last_name", e.target.value)}}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Email <small className='text-danger'>* {form.errors.email}</small></label>
                                        <input type="text" placeholder="Your email address" onChange={(e)=>{handleChange("email", e.target.value)}}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Phone <small className='text-danger'>* {form.errors.phone}</small></label>
                                        <input type="text" value={form.values.phone} placeholder="Phone" onChange={(e)=>{handleChange("phone", e.target.value)}}/>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Subject <small className='text-danger'>* {form.errors.subject}</small></label>
                                        <input type="text" placeholder="Subject" onChange={(e)=>{handleChange("subject", e.target.value)}}/>
                                    </div>
                                    <div className="col-md-12">
                                        <label>Message <small className='text-danger'>* {form.errors.message}</small></label>
                                        <textarea placeholder="Your message" rows="4" onChange={(e)=>{handleChange("message", e.target.value)}}></textarea>
                                    </div>
                                    <div className="col-md-12 text-center form-btn">
                                        <a href="#" className="btn main-btn" onClick={handleSubmit}>Send</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer-patten">
                <img className="w-100" src="./images/get-started-patten.png" alt=""/>
            </section>

            <Dialog
                open={confirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description" className='text-center'>
                    <CheckCircleOutlineIcon sx={{fontSize:'150px'}} color='success'/>
                    <h3>Thank you for getting in touch!</h3>
                    <br/>
                    <Typography sx={{letterSpacing: '2px'}}>We will get back in touch with you soon! 
                    <br/>Have a great day!</Typography>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{window.location = "/"}}>Close</Button>
                </DialogActions>
            </Dialog>

            <Footer/>
        </Fragment>
    )
}

export default Contact