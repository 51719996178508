import React, { useState } from 'react';
import {
    Box, TextField, Grid, Button,
    Typography, RadioGroup, FormControl,
    FormLabel, Radio, FormControlLabel,
    FormHelperText, Checkbox, TextareaAutosize
} from '@mui/material';
import Loading from '../../Loading';
import Images from '../Images';
import moment from 'moment';
import Joi from "../../utils/validator";
import Notify from '../Snakbar';
import {NotificationManager} from 'react-notifications';

import axios from 'axios';

export default function FormView(props) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ic = urlParams.get('ic')
    const statesList = [
        'AL', 'AZ', 'AR', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'ID', 
        'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI',
        'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NC',
        'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ];

    const [loading, setLoading] = useState(false)
    const [discuss, setDiscuss] = useState({
        "Retirement Planning": false,
        "Tax Reduction Strategies": false,
        "Create Tax-Free Income": false,
        "Business Owners": false,
        "Asset Protection": false,
        "Trust Setup": false,
        "Estate Planning": false,
        "Brokerage Second Opinion": false,
        "Portfolio Diversification": false,
        "Pension Protection": false,
        "Mortgage Protection": false,
        "Debt Reduction": false,
        "Beneficiary Review": false,
        "Annuity Review": false,
        "Life Insurance Review": false,
        "College Planning": false,
        "Premium Financing": false,
        "Senior Life Settlements": false,
        "Long Term Care": false,
        "Crypto": false,
    });

    const [age, setAge] = useState('');
    const [ageError, setAgeError] = useState(false);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const [phone, setPhone] = useState('');
    const [newPhone, setNewPhone] = useState("");
    const [phoneError, setPhoneError] = useState(false);

    const [selectedState, setSelectedState] = useState(props.selectedState);
    const [stateError, setStateError] = useState(false);

    const [other, setOther] = useState(false);
    const [info, setInfo] = useState('');

    const [textDiscuss, setTextDiscuss] = useState('');

    const [textDiscussError, setTextDiscussError] = useState(false);

    const handleChangeDiscuss = (key) => {
        let d = { ...discuss }
        d[key] = !d[key]
        setDiscuss(d)
    }

    const handleEmailChange = (val) => {
        if (Joi.validateToPlainErrors(val, Joi.string().email().label('Email').required())) {
            setEmailError(Joi.validateToPlainErrors(val, Joi.string().email().label('Email').required())[0])
        } else {
            setEmailError(false)
        }
        setEmail(val)
    }

    const objToArray = (data) => {
        let array = []

        for (const [key, value] of Object.entries(data)) {
            if (value) {
                array.push(key)
            }
        }

        return array
    }

    const handleSubmit = async () => {
        handleClick();
        if (isValid()) {
            let d = [...objToArray(discuss)]
            if (other !== false) {
                d.push(textDiscuss)
            }
            setLoading(true)
            var formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('phone', newPhone);
            formData.append('age_range', age);
            formData.append('discuss', d);
            formData.append('message', info);
            formData.append('appt_datetime', moment(props.date).format("YYYY-MM-DD") + " " + props.time);
            formData.append('timezone', props.timezone);
            formData.append('state', selectedState);
            formData.append('ic', ic);

            // Print the all form data in console   
            for (var pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }

            await axios({
                url:props.endpoint_url+"/save.php",
                method:"POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                data:formData
            }).then((response)=>{
                if(response.data.success){
                    props.onSubmit(phone)
                    props.setStep(3)
                } else {
                    NotificationManager.error('Unable to Submit', response.data?.reason && 'Please refresh and try again!', 3000);
                }
                setLoading(false)
            }).catch((error)=>{
                console.log(error);
                setLoading(false)
            });
        }
    }

    const handleRadioChange = (value) => {

        if (value === '21 to 44') {
            setAgeError(false)
        } else if (value === '45 to 58') {
            setAgeError(false)
        } else if (value === '59+') {
            setAgeError(false)

        } else {
            setAgeError(true);
        }
        setAge(value);

    }

    const handleStateChange = (value) => {
        if (!value) {
            setStateError(true)
        }
        else {
            setStateError(false)
        }
        setSelectedState(value);
    }

    const isValid = () => {
        if (other && textDiscuss.length === 0) {
            setTextDiscussError(true)
            setTextDiscussError('Required')
            return false;
        }
        if (name && !nameError && !phoneError && !emailError && !ageError && !stateError) {
            return true;
        } else {

            handleNameChange(name);
            handlePhoneChange(phone);
            handleEmailChange(email);
            handleRadioChange(age);
            handleStateChange(selectedState);
            return false;
        }
    }

    const handlePhoneChange = (val) => {
        setNewPhone(val);
        setPhone(validatePhone(val))
        props.setPhone(validatePhone(val))
    }

    const validatePhone = (input) => {
        input = input.replace(/\D/g, '');
        input = input.substring(0, 10);

        var size = input.length;

        if (size < 10) {
            setPhoneError("Please fill valid Phone number")
        }
        else {
            setPhoneError("")
        }
        if (size === 0) {
            setPhoneError("Phone is required")
        } else if (size < 4) {
            input = '(' + input;

        } else if (size < 7) {
            input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);

        } else {
            input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10);


        }

        return input;
    };

    const handleNameChange = (val) => {
        if (val.length <= 1) {
            setNameError("Name is required!");
        } else {
            setNameError(false)
        }
        setName(val)
    }

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
        //   alert("hello")
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <Box>
            <Loading isLoading={loading}/> 

            <FormLabel style={{ fontWeight: 'bold', color: 'black' }}>Enter Details</FormLabel>

            <Grid container spacing={4} sx={{ pt: 1 }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label='Full Name*' fullWidth
                        variant="outlined"
                        value={name}
                        onChange={(e) => { handleNameChange(e.target.value) }}
                        helperText={nameError}
                        error={nameError}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label='Email Address*' fullWidth
                        variant="outlined"
                        value={email}
                        onChange={(e) => { handleEmailChange(e.target.value) }}
                        helperText={emailError}
                        error={emailError}

                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Mobile Number*"
                        InputProps={{
                            startAdornment: (
                                <img style={{ paddingRight: "10px" }} src={Images.us_flag} alt="" width="30px"></img>
                            ),
                        }}
                        value={phone}
                        onChange={(e) => { handlePhoneChange(e.target.value) }}
                        helperText={phoneError}
                        error={phoneError}
                    />
                    <small>(normal text rates apply for reminders)</small>
                </Grid>

                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                    <FormControl>
                        <FormLabel style={{ fontWeight: 'bold', color: 'black' }}>Age Range*</FormLabel>
                        <RadioGroup
                            row
                            onChange={(e) => { handleRadioChange(e.target.value) }}
                            helperText='Please Select Age'
                            error={ageError}
                            style={{ flexWrap: 'nowrap' }}
                            className="radio-button-holder"
                        >
                            <FormControlLabel value="21 to 44" control={<Radio />} label="21 to 44" />
                            <FormControlLabel value="45 to 58" control={<Radio />} label="45 to 58" />
                            <FormControlLabel value="59+" control={<Radio />} label="59+" />
                        </RadioGroup>
                        <FormHelperText className='text-danger'>{ageError && 'Please Select Age'}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={6} >
                    <FormLabel style={{ fontWeight: 'bold', color: 'black' }}>State* &nbsp;</FormLabel>
                    <Box sx={{display: 'flex', justifyContent:'start', alignItems:"center"}}>
                        <Button
                            variant={"contained"}
                            style={{ ml:2, display: 'flex', direction: 'row', px: 0, minWidth: '45px', width: '45px' }}
                            outlinedButton
                            size="small"
                            // onClick={(e) => { handleStateChange(e.target.value) }}
                        >
                            {selectedState}
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={12}>
                <Grid item xs={12} className="question-check-holder" >
                    <FormLabel style={{ fontWeight: 'bold', color: 'black', marginTop: "30px" }}> Please check off the following item(s) you have questions about:</FormLabel>
                    <Grid container sx={{fontSize:'10px'}}>
                        {
                            Object.entries(discuss).map((key) => (
                                <Grid item xs={props.isMobile ? "" : 4} >
                                    <FormControlLabel
                                        className="discuss-selector"
                                        control={
                                            <Checkbox color="primary" checked={key[1]} onClick={(i) => { handleChangeDiscuss(key[0]) }} />
                                        }
                                        label={key[0]}
                                    />
                                </Grid>
                            ))
                        }
                        {/* <Grid item xs={props.isMobile ? "" : 6} >
                            <Box sx={{ justifyContent: "start", display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    sx={{ pl: 0 }}
                                    color="primary"
                                    onClick={e => {
                                        setOther(e.target.checked)
                                        setTextDiscuss('')
                                        setTextDiscussError(false)
                                    }}
                                    value={textDiscuss}
                                />
                                <TextField
                                    disabled={other ? false : true}
                                    label="Other"
                                    value={textDiscuss}
                                    helperText={textDiscuss.length === 0 ? textDiscussError : ""}
                                    error={textDiscuss.length === 0 ? textDiscussError : ""}
                                    onChange={(e) => { setTextDiscuss(e.target.value) }}
                                />
                            </Box>
                        </Grid> */}
                    </Grid>
                </Grid>

                <Grid item xs={12} className="pt-0" >
                    <FormLabel style={{ fontWeight: 'bold', color: 'black', marginTop: "30px" }}>Enter specific questions below:</FormLabel>
                    <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', height: '101px', backgroundColor: 'transparent', borderRadius: "5px", padding: "3px 5px" }}
                        onChange={(e) => { setTextDiscuss(e.target.value) }}
                        defaultValue={textDiscuss}
                        placeholder="Question 1&#10;Question 2&#10;Question 3"
                    />
                </Grid>

                <Grid item xs={12} className="pt-0" >
                    <FormLabel style={{ fontWeight: 'bold', color: 'black', marginTop: "30px" }}>Please share anything that will help prepare for your meeting:</FormLabel>
                    <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', height: '101px', backgroundColor: 'transparent', borderRadius: "5px", padding: "3px 5px" }}
                        onChange={(e) => { setInfo(e.target.value) }}
                        defaultValue={info}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} className="pt-3" >
                <Box sx={{
                    display: 'flex',
                    justifyContent:"space-between",
                    alignItems:'center'
                }}>
                    <Button variant="contained" onClick={handleSubmit} >Schedule Event</Button>
                    <div class="ml-3">
                        <small>
                        By scheduling an appointment, you agree to our  <a href="https://askadvisorpro.com/terms-of-use" target="_blank">Terms of Use</a>
                        </small>
                    </div>
                </Box>
                {
                    (nameError || emailError || phoneError || ageError || stateError || textDiscussError)
                        ? <Notify
                            open={open}
                            onClose={handleClose}
                            severity="error"
                            message="Validation Issue! Please check the form."
                        />
                        : ""
                }
            </Grid>
        </Box>

    )
}