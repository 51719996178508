import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Joi from '../../utils/validator';
import { Form } from 'antd';
import notifier from '../../utils/notifier';
import { propTypes } from 'react-bootstrap/esm/Image';
import { TextField, FormControl, FormHelperText } from '@mui/material';
const CREATE_INFORMATION_SCHEMA = {
  email: Joi.string().email().label('Email').required(),
  phone: Joi.string().label('Phone').required(),
}

const initialState = {
  fullname: "",
  email: "",
  phone: "",
};

const Slide1 = props => {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({ ...initialState });

  const handleChange = useCallback((key, val) => {
    // Validate individual
    if(key == "phone" && formatPhone(val).length != 14 ){
      setErrors(errors => ({
        ...errors,
        [key]: "Please enter valid phone number",
      }));
    } else {
      setErrors(errors => ({
        ...errors,
        [key]: Joi.validateToPlainErrors(val, CREATE_INFORMATION_SCHEMA[key]),
      }));
    }
    

    setData({
      ...data,
      [key]: key == "phone" ? formatPhone(val) : val,
    });
  })

  console.log(data.phone.length)

  const formatPhone = (input) => {
    input = input.replace(/\D/g, '');
    input = input.substring(0, 10);

    var size = input.length;

    if (size < 4) { input = '(' + input; } 
    else if (size < 7) { input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6); } 
    else { input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10); }

    return input;
  }

  const handleNext = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // Validate all fields
    const errors = Joi.validateToPlainErrors(data, CREATE_INFORMATION_SCHEMA);
    setErrors(errors);

    // console.log("Error",errors);

    if (Joi.hasPlainError(errors)) {
      // notifier.error('Please fix errors');
      return;
    }
    props.setSlideData({ ...data, ...(props.slideData) });
    props.setStep(2);
    props.setButton("next");
  }

  return (
    <div className={(props.button == "back" ? "w3-animate-left" : "w3-animate-right") + " step_slider_box"} >
      <div className="step_group" >
        <div className="row">
          <div className="col-md-6 mob-steps">
            <div className="step_count">
              <img src="./images/mob-step_1.png" alt="" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="step_content_card">
              <div>
                <h3>Worker's Compensation Survey</h3>
                <div className="progress step-progress">
                  <div className="progress-bar" role="progressbar" style={{ width: '10%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>0%</div>
                </div>
              </div>
              <div className="step_content_form">
                <h4>Your Information</h4>
                <div className="col-md-12">
                  <div className="contact_f_box mui-form-input mui-input-active">
                    <FormControl
                      fullWidth
                      error={Joi.getFirstPlainError(errors, 'fullname') ? 'error' : ''}
                    >
                      <TextField
                        error={Joi.getFirstPlainError(errors, 'fullname') ? 'error' : ''}
                        id="outlined-basic" label="Name (Optional)"
                        variant="outlined"
                        value={data.fullname}
                        placeholder="Name (Optional)"
                        onChange={v => handleChange('fullname', v.target.value)}
                        type="text" />
                      <FormHelperText>{Joi.getFirstPlainError(errors, 'fullname')}</FormHelperText>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="contact_f_box mui-form-input mui-input-active">
                    <FormControl
                      fullWidth
                      error={Joi.getFirstPlainError(errors, 'email') ? 'error' : ''}
                    >
                      <TextField
                        error={Joi.getFirstPlainError(errors, 'email') ? 'error' : ''}
                        id="outlined-basic" label="Email" variant="outlined"
                        value={data.email}
                        placeholder="Email"
                        onChange={v => handleChange('email', v.target.value)}
                        type="email" />
                      <FormHelperText>{Joi.getFirstPlainError(errors, 'email')}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="contact_f_box mui-form-input mui-input-active">
                    <FormControl
                      fullWidth
                      error={Joi.getFirstPlainError(errors, 'phone') ? 'error' : ''}
                    >
                      <TextField
                        error={Joi.getFirstPlainError(errors, 'phone') ? 'error' : ''}
                        id="outlined-basic" label="Phone" variant="outlined"
                        value={data.phone}
                        placeholder="Phone"
                        onChange={v => handleChange('phone', v.target.value)}
                         />
                      <FormHelperText>{Joi.getFirstPlainError(errors, 'phone')}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
                {/* <div className="contact_f_box mui-form-input">
                <FormControl
                  fullWidth
                  error={Joi.getFirstPlainError(errors, 'phone') ? 'error' : ''}
                >
                  <TextField
                    error={Joi.getFirstPlainError(errors, 'phone') ? 'error' : ''}
                    id="outlined-basic" label="Phone Number" variant="outlined"
                    value={data.phone}
                    placeholder="Phone Number"
                    onChange={v => handleChange('phone', v.target.value)}
                    type="text" />
                  <FormHelperText>{Joi.getFirstPlainError(errors, 'lenght_of_employement')}</FormHelperText>
                </FormControl>
              </div> */}
                <div className="text-center step-btn-group">
                  {/* <a className="step-btn mr-2" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <div className="btn sec-btn" value="1" data-dismiss="modal">Back</div>
                  </a> */}
                  <a className="step-btn"  role="button" data-slide="next">
                    <div className="btn main-btn" value="2" onClick={() => { handleNext(); }}>Next</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 desktop-steps">
            <div className="step_count">
              <img src="./images/step_1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Slide1;