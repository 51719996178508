import React from 'react'
import moment from 'moment';

function Footer() {
    return (
        <footer className="pt-0">
            <div className="container">
                <div className="row">   
                    <div className="col-md-12 text-center">
                        <b><p className="copyright">Copyright © {moment().year()}. <a href="#">Benefit Specialists, LLC.</a>  All Rights Reserved.</p>
                        <p className="footer-text">
                            Benefit Specialists, LLC is a marketing company only and we are not a financial advisor, broker dealer, investment advisory firm, insurance underwriter/carrier, insurance agency or a CPA. If you set up an appointment, the person you will be speaking with is not employed by us.  Further, by providing your contact information and setting up an appointment, you agree to our terms of service at: <a href="/terms-of-use">https://www.askadvisorpro.com/terms-of-use</a>.
                        </p></b>
                        <p className="footer-other-link mb-0">
                            <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-use">Terms of Use</a> | <a href="/contact-us">Contact Us</a> | <a href="mailto:support@askadvisorpro.com">support@askadvisorpro.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer