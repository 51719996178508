import React,{ useState, useEffect } from 'react'
import { Paper, Box, Button, Typography} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Calender from './Components/Calender/index';
import Loading from './Loading';
import AppointmentDetail from './Components/AppointmentDetail';
import TimerView from './Components/TimerView';
import FormView from './Components/FormView';
import Confirmed from './Components/Confirmed';
import axios from 'axios';
import moment from 'moment';
import './style.scss';
import StateSelectDialog from './Components/StateSelectDialog';
import endpoint_url from './utils/conf';
import Footer from '../../components/Footer';

const style = {
    rightPanel: {
        display:"flex", justifyContent:"center", alignItem:"center", p:5, width:'100%'
    }
}

export default function Containers() {
    const hasWindow = typeof window !== 'undefined';

    const [blockTillDate, setBlockTillDate] = useState("2022-01-01"); 

    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [availableDates, setAvailableDates] = useState([])
    const [appointmentCount, setAppointmentCount] = useState(null)
    const [holidays, setHolidays] = useState([])

    const [selectedState, setSelectedState] = useState(null)

    const [phone, setPhone] = useState(null)
    const [timeSelected, setTimeSelect] = useState(null)
    const [dateSelected,setDateSelect] = useState(null);
    const [timezoneSelected,setTimezoneSelected] = useState(null);
    
    const [isDesktop, setIsDesktop] = useState(getWindowDimensions().width > 1100);
    const [isTablet,setIsTablet] = useState(getWindowDimensions().width >= 600 && getWindowDimensions().width <= 1100);
    const [isMobile,setIsMobile] = useState(getWindowDimensions().width < 600);

    useEffect(()=>{
        setTimeSelect(null)
    },[dateSelected])

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setIsDesktop(getWindowDimensions().width > 1100);
                setIsTablet(getWindowDimensions().width >= 600 && getWindowDimensions().width <= 1100);
                setIsMobile(getWindowDimensions().width < 600);
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {
            width,
            height,
        };
    }

    useEffect(async () => {
        if(selectedState){
            setLoading(true)
            await getDates();
            await getTimeZone()
            setLoading(false)
        }
    }, [selectedState]);
    
    const getDates = async () => {
        await axios({
          url:endpoint_url+"/get-date.php?state="+selectedState,
          method:"GET",
          headers:{
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          }
        }).then((response)=>{
            setAvailableDates(response.data.data)
            setAppointmentCount(response.data.appointment_count)
            setBlockTillDate(response.data.block_till_date)
            setHolidays(response.data.holidays)
        }).catch((error)=>{
            setAvailableDates([])
        });
    }

    const getTimeZone = async () => {
        await axios({
          url:endpoint_url+"/getTimeZone.php",
          method:"GET",
          headers:{
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          }
        }).then((response)=>{
            console.log("timezone::",response.data)
            setTimezoneSelected(response.data[selectedState])
        }).catch((error)=>{
        });
    }
    
    return (
        <>
            <Paper sx={
                isDesktop ? 
                { 
                    width: dateSelected === null ? "60%" : "65%", 
                    display:"flex", justifyContent:"start", alignItem:"center", 
                    mt:5, mb:1, mx:'auto',
                    border: '1px solid #d3d3d3',
                    backgroundColor:"#f0f0f0",
                    position:"relative"
                }
                : { width:"90%", alignItem:"center", mt:5, mx:'auto', backgroundColor:"#f0f0f0", position:"relative"}
            }>
                {
                    !isMobile && <img src="images/AAP-transparent.png" alt="logo" className='fade_logo'/>
                }
                <Loading isLoading={loading}/> 
                {
                    step === 3 
                    ? <Confirmed time={timeSelected} date={dateSelected} timezone={timezoneSelected} phone={phone} />
                    : <>
                        <Box sx={{px:isMobile ? 0 : 5, width: isMobile || isTablet ? '100%' : '60%'}}>
                            <AppointmentDetail 
                                step={step} 
                                isDesktop={isDesktop}
                                isTablet={isTablet}
                                isMobile={isMobile}
                                setStep={setStep}
                                dateSelected={dateSelected}
                                timezoneSelected={timezoneSelected}
                                timeSelected={timeSelected}
                            />
                        </Box>
                        
                        {/* Mobile View */}
                        <Box 
                            sx={ 
                                isDesktop 
                                ? { ...style.rightPanel, borderLeft: '0.1px solid #cdcdcd' } 
                                : { ...style.rightPanel, borderTop: '0.1px solid #cdcdcd' } 
                            }>
                            {
                                step === 1
                                ? <>
                                    {
                                        (isDesktop || isTablet || (isMobile && dateSelected === null)) && <Box>
                                            {
                                                !loading && <Calender 
                                                    dateSelected={dateSelected}
                                                    setDateSelect={setDateSelect}
                                                    isMobile ={isMobile}
                                                    dates={availableDates} 
                                                    appointmentCount={appointmentCount}
                                                    blockTillDate={blockTillDate}
                                                    holidays={holidays}
                                                    />
                                            }
                                        </Box>
                                    }
                                    {/* Todo: If date selected then show the timer */}
                                    {
                                        dateSelected && 
                                        <Box width={'100%'}>
                                            { isMobile &&
                                                <Button fullWidth variant="contained" color={"success"} sx={{mb:1}} onClick={()=>{setDateSelect(null)}}>
                                                    <ArrowBackIcon /> &nbsp; Back
                                                </Button>
                                            }
                                            {
                                                !loading && <TimerView 
                                                    timezoneSelected={timezoneSelected} 
                                                    setTimezoneSelected={setTimezoneSelected}
                                                    timeSelected={timeSelected}
                                                    setTimeSelect={setTimeSelect}
                                                    setStep={setStep}
                                                    appointmentCount={appointmentCount}
                                                    dateSelected={dateSelected}
                                                    setDateSelect={setDateSelect}
                                                    blocked={availableDates[moment(dateSelected.toString()).format('YYYY-MM-DD')]}
                                                    isMobile={isMobile} />
                                            }
                                        </Box>
                                    }
                                </> : step === 2 ? <FormView 
                                        time={timeSelected}
                                        setPhone={setPhone}
                                        date={dateSelected}
                                        timezone={timezoneSelected}
                                        onSubmit={()=>{console.log("submitted")}}
                                        setStep={setStep}
                                        endpoint_url={endpoint_url}
                                        selectedState={selectedState}
                                        isMobile ={isMobile}
                                        isTablet = {isTablet}
                                /> : <>
                                    <StateSelectDialog
                                        setSelectedState={setSelectedState}
                                        setStep={setStep}
                                    />
                                    <Calender 
                                        dateSelected={dateSelected}
                                        setDateSelect={setDateSelect}
                                        isMobile ={isMobile}
                                        dates={availableDates} 
                                        appointmentCount={appointmentCount}
                                        blockTillDate={blockTillDate}
                                        holidays={holidays}
                                        />
                                </>
                            }
                        </Box>
                    </>
                }
            </Paper>
            <Footer/>
            {/* <!-- Policy end--> */}
            <section className="footer-patten">
                <img className="w-100" src="./images/get-started-patten.png" alt="" />
            </section>
            {/* <!-- Policy end--> */}
            
        </>
    )
}


